<template>
  <transition name="slide-right" :appear="true">
    <div class="view-container">
      <div class="card">
        <div class="card-body">
          <div v-if="parametresType" class="analytic-form__options">
            <component
              @reportRequest="makeChart"
              v-bind:is="parametresType"
            ></component>
          </div>
          <div class="analytic-form__charts w-full">
            <div
              v-if="reportRequest && !reportData"
              class="
                w-full
                flex
                justify-content-center
                align-item-center
                preloader
              "
            >
              <preloader />
            </div>
            <div v-else-if="reportData">
              <component :groupId="groupId" v-bind:is="reportType"></component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { onBeforeMount, onBeforeUnmount, ref } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { computed } from "@vue/runtime-core";
import BaseReportParametres from "../../feature/analytics/report-parameters/BaseReportParametres.vue";
import BaseReport from "../../feature/analytics/reports/BaseReport.vue";
import Preloader from "@/components/Technical/Preloader.vue";
import GroupReportParametres from "../../feature/analytics/report-parameters/GroupReportParametres.vue";
import GroupReport from "../../feature/analytics/reports/GroupReport.vue";
import GeneralDynamicParametres from "../../feature/analytics/report-parameters/GeneralDynamicParametres.vue";
import ReferenceReportParametres from "../../feature/analytics/report-parameters/ReferenceReportParametres.vue";
import ReferenceUsersReportParametres from "../../feature/analytics/report-parameters/ReferenceUsersReportParametres.vue";
import RepeatedReportParametres from "../../feature/analytics/report-parameters/RepeatedReportParametres.vue";
import DemographicReportParametres from "../../feature/analytics/report-parameters/DemographicReportParametres.vue";
import DemographicReport from "../../feature/analytics/reports/DemographicReport.vue";
import { useStore } from "vuex";
import ReferenceUsersReport from "../../feature/analytics/reports/ReferenceUsersReport.vue";
import SummaryTableParametres from "../../feature/analytics/report-parameters/SummaryTableParametres.vue";
import SummaryReport from "../../feature/analytics/reports/SummaryReport.vue";
import GeneralReport from "@/feature/analytics/reports/GeneralReport.vue";
import GeneralReportParameters from "@/feature/analytics/report-parameters/GeneralReportParameters.vue";

const reportsParamsDictionary = {
  base: "base-report-parametres",
  group: "group-report-parametres",
  dynamic: "general-dynamic-parametres",
  reference: "reference-users-report-parametres",
  repeated: "repeated-report-parametres",
  demographic: "demographic-report-parametres",
  summary: "summary-table-parametres",
  general: "general-report-parameters"
};

const reportsDictionary = {
  base: "base-report",
  group: "group-report",
  dynamic: "group-report",
  reference: "reference-users-report",
  repeated: "group-report",
  demographic: "demographic-report",
  summary: "summary-report",
  general: "general-report",
};

export default {
  components: {
    BaseReportParametres,
    BaseReport,
    Preloader,
    GroupReportParametres,
    GroupReport,
    GeneralDynamicParametres,
    ReferenceReportParametres,
    ReferenceUsersReportParametres,
    RepeatedReportParametres,
    DemographicReportParametres,
    DemographicReport,
    ReferenceUsersReport,
    SummaryTableParametres,
    SummaryReport,
    GeneralReportParameters,
    GeneralReport,
  },
  setup() {
    const store = useStore();
    const { name } = useRoute().params;
    const parametresType = ref(null),
      reportType = ref(null),
      reportRequest = ref(false),
      groupId = ref(null);
    const reportData = computed(() => store.state.analytics.reportData),
      reportReservedData = computed(
        () => store.state.analytics.reportReservedData
      );
    const currentAnimation = ref("");

    const makeChart = (data) => {
      store.commit("analytics/setReportData", null);
      groupId.value = data.group_id;
      switch (name) {
        case "base":
          store.dispatch("analytics/getBaseReportData", data);
          break;
        case "group":
          store.dispatch("analytics/getGroupReportData", data);
          break;
        case "dynamic":
          store.dispatch("analytics/getGeneralDymanicData", data);
          break;
        case "repeated":
          store.dispatch("analytics/getRepeatedData", data);
          break;
        case "demographic":
          store.dispatch("analytics/getDifferencesSexData", data);
          store.dispatch("analytics/getDifferencesAgeData", data);
          break;
        case "reference":
          groupId.value = data.group_id;
          store.dispatch("analytics/getReferenceUsersData", data);
          break;
        case "summary":
          groupId.value = data.group_id;
          store.dispatch("analytics/getSummaryTable", data);
          break;
        case "general":
          groupId.value = data.group_id;
          store.dispatch("analytics/getGeneralReportTable", data);
          break;
      }
      reportRequest.value = true;
    };

    onBeforeMount(() => {
      parametresType.value = reportsParamsDictionary[name];
      reportType.value = reportsDictionary[name];
    });

    onBeforeUnmount(() => {
      store.commit("analytics/setReportData", null);
    });

    return {
      parametresType,
      reportType,
      reportRequest,
      reportData,
      reportReservedData,
      makeChart,
      groupId,
      currentAnimation,
    };
  },
};
</script>

<style scored lang="scss">
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.2s ease-out;
}

.slide-right-leave-to,
.slide-right-enter-from {
  opacity: 0;
  transform: translateX(1000px);
}
.preloader {
  min-height: 200px;
}

.back-analytics {
  i {
    margin-right: 5px;
  }
}

.card-header {
  padding-bottom: 0;
}

.card-body {
  padding-top: 0;
}

.back-btn {
  display: flex;
  padding-bottom: 20px;
}

.hide {
  animation: slide-in-left 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) reverse;
}
.show {
  animation: slide-in-left 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  65% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}
</style>